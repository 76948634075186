














import Vue from 'vue';
import { codemirror } from 'vue-codemirror';

// Languages
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/mode/htmlmixed/htmlmixed.js';
import 'codemirror/mode/markdown/markdown.js';

// Themes
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/neo.css';

// Highlighting
import 'codemirror/addon/selection/active-line.js';
import 'codemirror/addon/selection/mark-selection.js';
import 'codemirror/addon/scroll/annotatescrollbar.js';
import 'codemirror/addon/comment/comment.js';
import 'codemirror/addon/edit/matchbrackets.js';

// Search
import 'codemirror/addon/search/search.js';
import 'codemirror/addon/search/searchcursor.js';
import 'codemirror/addon/search/matchesonscrollbar.js';
import 'codemirror/addon/search/match-highlighter.js';
import 'codemirror/addon/dialog/dialog.js';
import 'codemirror/addon/dialog/dialog.css';

// keyMap
import 'codemirror/keymap/sublime.js';

// foldGutter
import 'codemirror/addon/fold/foldgutter.js';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/fold/brace-fold.js';
import 'codemirror/addon/fold/comment-fold.js';
import 'codemirror/addon/fold/foldcode.js';
import 'codemirror/addon/fold/indent-fold.js';
import 'codemirror/addon/fold/markdown-fold.js';
import 'codemirror/addon/fold/xml-fold.js';

// Other
import 'codemirror/addon/display/autorefresh.js';

import { debounce } from 'lodash';

export default Vue.extend({
  name: 'CodeMirrorWrapper',
  components: {
    codemirror,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '60vh',
    },
    width: {
      type: String,
      default: '100%',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    theme: {
      type: String,
      default: 'default',
    },
    language: {
      type: String,
      default: 'json',
    },
    //  ? Is there a way to remove the autofocus on 'div.v-dialog__content.v-dialog__content--active' to be able to focus Json-editor, when opened?
    autofocus: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      buffer: null,
      isDirty: false,
      languages: {
        // TODO: Load languages dynamically on use
        javascript: { name: 'javascript', typescript: false },
        typescript: { name: 'javascript', typescript: true },
        json: { name: 'javascript', json: true },
        html: 'text/html',
        markdown: 'text/x-markdown',
      },
      themes: {
        dark: 'base16-dark',
        light: 'base16-light',
        neo: 'neo',
        default: 'default',
      },
    };
  },
  computed: {
    cmOptions() {
      return {
        ...{
          // Default options
          keyMap: 'sublime',
          mode: this.languages[this.language],
          theme: this.themes[this.theme],
          foldGutter: true,
          lineNumbers: true,
          lineWrapping: true,
          tabSize: 2,
          gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
          line: true,
          matchBrackets: true,
          showCursorWhenSelecting: true,
          styleActiveLine: true,
          styleSelectedText: true,
          autoRefresh: true,
        },
        ...this.options,
      };
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.isDirty = false;
        this.buffer = newValue || '';
      },
    },
    buffer: {
      immediate: true,
      handler() {
        this.isDirty = true;
        this.update();
      },
    },
  },
  methods: {
    update: debounce(function () {
      this.$emit('input', this.buffer);
      this.isDirty = false;
    }, 100),
    onBlur(e) {
      this.$emit('change', this.buffer);
      this.$emit('blur', this.buffer);
    },
    onkeydown(cm, e) {
      if (e.key == 'Escape') {
        e.stopPropagation();
      }
    },
  },
});
